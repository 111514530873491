@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-size: 18px;
        letter-spacing: 1.2px;
    }
    a {
        @apply text-primary-500 hover:text-primary-900 hover:underline;
    }
}

